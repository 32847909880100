exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-apps-jsx": () => import("./../../../src/pages/apps.jsx" /* webpackChunkName: "component---src-pages-apps-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-blog-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/blog/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-blog-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-japanese-games-drag-drop-hiragana-kana-bento-jsx": () => import("./../../../src/pages/japanese/games/drag-drop-hiragana-kana-bento.jsx" /* webpackChunkName: "component---src-pages-japanese-games-drag-drop-hiragana-kana-bento-jsx" */),
  "component---src-pages-japanese-games-drag-drop-katakana-kana-bento-jsx": () => import("./../../../src/pages/japanese/games/drag-drop-katakana-kana-bento.jsx" /* webpackChunkName: "component---src-pages-japanese-games-drag-drop-katakana-kana-bento-jsx" */),
  "component---src-pages-japanese-games-kana-bento-jsx": () => import("./../../../src/pages/japanese/games/kana-bento.jsx" /* webpackChunkName: "component---src-pages-japanese-games-kana-bento-jsx" */),
  "component---src-pages-japanese-games-kana-grid-jsx": () => import("./../../../src/pages/japanese/games/kana-grid.jsx" /* webpackChunkName: "component---src-pages-japanese-games-kana-grid-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-support-jsx": () => import("./../../../src/pages/support.jsx" /* webpackChunkName: "component---src-pages-support-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */)
}

